import React from 'react';

import Item from './Item';

import { container, list as listStyle } from './mainMenu.style';

const MainMenu = ({ items }) => (
  <nav css={container}>
    <ul css={listStyle}>
      {items.map((item) => (
        <li key={`menu-item-${item.frontmatter.title}`}>
          <Item {...item} />
        </li>
      ))}
    </ul>
  </nav>
);

export default MainMenu;
