import { css } from '@emotion/react';

import { fluid } from '../../style-tokens';

export const container = css`
  padding-top: ${fluid(0.5, 2.5)};
`;

export const list = css`
  list-style: none;
  margin-bottom: 0;
  margin-top: ${fluid(0.75, 1.5)};
  padding-left: 0;
`;
