import { graphql } from 'gatsby';
import { useTitle } from 'hooked-head';
import React from 'react';

import Layout from '../components/Layout';
import MainMenu from '../components/MainMenu';

import { Provider as ContextProvider } from '../context';

export const data = graphql`
  query {
    interviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/interviews/.*.md$/" } }
      sort: { fields: frontmatter___number, order: DESC }
    ) {
      nodes {
        ...MainMenuItem
      }
    }
  }
`;

const Interviews = ({
  data: {
    interviews: { nodes: interviews },
  },
}) => {
  useTitle('Interviews');

  return (
    <ContextProvider value={{}}>
      <Layout logoColor="black">
        <MainMenu items={interviews} />
      </Layout>
    </ContextProvider>
  );
};

export default Interviews;
